"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _default = {
  "hljs": {
    "display": "block",
    "overflowX": "auto",
    "padding": "0.5em",
    "background": "white",
    "color": "black"
  },
  "hljs-subst": {
    "color": "black"
  },
  "hljs-comment": {
    "color": "#555555",
    "fontStyle": "italic"
  },
  "hljs-keyword": {
    "color": "#000000",
    "fontWeight": "bold"
  },
  "hljs-attribute": {
    "color": "#000000",
    "fontWeight": "bold"
  },
  "hljs-selector-tag": {
    "color": "#000000",
    "fontWeight": "bold"
  },
  "hljs-meta-keyword": {
    "color": "#000000",
    "fontWeight": "bold"
  },
  "hljs-doctag": {
    "color": "#000000",
    "fontWeight": "bold"
  },
  "hljs-name": {
    "color": "#000000",
    "fontWeight": "bold"
  },
  "hljs-string": {
    "color": "#000080"
  },
  "hljs-type": {
    "color": "#000000"
  },
  "hljs-number": {
    "color": "#000000"
  },
  "hljs-selector-id": {
    "color": "#000000"
  },
  "hljs-selector-class": {
    "color": "#000000"
  },
  "hljs-quote": {
    "color": "#000000"
  },
  "hljs-template-tag": {
    "color": "#000000"
  },
  "hljs-deletion": {
    "color": "#000000"
  },
  "hljs-title": {
    "color": "#fb2c00"
  },
  "hljs-section": {
    "color": "#fb2c00"
  },
  "hljs-title>.hljs-built_in": {
    "color": "#008080",
    "fontWeight": "normal"
  },
  "hljs-regexp": {
    "color": "#5e1700"
  },
  "hljs-symbol": {
    "color": "#5e1700"
  },
  "hljs-variable": {
    "color": "#5e1700"
  },
  "hljs-template-variable": {
    "color": "#5e1700"
  },
  "hljs-link": {
    "color": "#5e1700"
  },
  "hljs-selector-attr": {
    "color": "#5e1700"
  },
  "hljs-selector-pseudo": {
    "color": "#5e1700"
  },
  "hljs-built_in": {
    "color": "#000080",
    "fontWeight": "bold"
  },
  "hljs-literal": {
    "color": "#000080",
    "fontWeight": "bold"
  },
  "hljs-bullet": {
    "color": "#397300"
  },
  "hljs-code": {
    "color": "#397300"
  },
  "hljs-addition": {
    "color": "#397300"
  },
  "hljs-class": {
    "color": "#6f1C00",
    "fontWeight": "bold"
  },
  "hljs-meta": {
    "color": "#1f7199"
  },
  "hljs-meta-string": {
    "color": "#4d99bf"
  },
  "hljs-emphasis": {
    "fontStyle": "italic"
  },
  "hljs-strong": {
    "fontWeight": "bold"
  }
};
exports["default"] = _default;