"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _default = {
  "hljs": {
    "display": "block",
    "overflowX": "auto",
    "padding": "0.5em",
    "background": "#2E3440",
    "color": "#D8DEE9"
  },
  "hljs-subst": {
    "color": "#D8DEE9"
  },
  "hljs-selector-tag": {
    "color": "#81A1C1"
  },
  "hljs-selector-id": {
    "color": "#8FBCBB",
    "fontWeight": "bold"
  },
  "hljs-selector-class": {
    "color": "#8FBCBB"
  },
  "hljs-selector-attr": {
    "color": "#8FBCBB"
  },
  "hljs-selector-pseudo": {
    "color": "#88C0D0"
  },
  "hljs-addition": {
    "backgroundColor": "rgba(163, 190, 140, 0.5)"
  },
  "hljs-deletion": {
    "backgroundColor": "rgba(191, 97, 106, 0.5)"
  },
  "hljs-built_in": {
    "color": "#8FBCBB"
  },
  "hljs-type": {
    "color": "#8FBCBB"
  },
  "hljs-class": {
    "color": "#8FBCBB"
  },
  "hljs-function": {
    "color": "#88C0D0"
  },
  "hljs-function > .hljs-title": {
    "color": "#88C0D0"
  },
  "hljs-keyword": {
    "color": "#81A1C1"
  },
  "hljs-literal": {
    "color": "#81A1C1"
  },
  "hljs-symbol": {
    "color": "#81A1C1"
  },
  "hljs-number": {
    "color": "#B48EAD"
  },
  "hljs-regexp": {
    "color": "#EBCB8B"
  },
  "hljs-string": {
    "color": "#A3BE8C"
  },
  "hljs-title": {
    "color": "#8FBCBB"
  },
  "hljs-params": {
    "color": "#D8DEE9"
  },
  "hljs-bullet": {
    "color": "#81A1C1"
  },
  "hljs-code": {
    "color": "#8FBCBB"
  },
  "hljs-emphasis": {
    "fontStyle": "italic"
  },
  "hljs-formula": {
    "color": "#8FBCBB"
  },
  "hljs-strong": {
    "fontWeight": "bold"
  },
  "hljs-link:hover": {
    "textDecoration": "underline"
  },
  "hljs-quote": {
    "color": "#4C566A"
  },
  "hljs-comment": {
    "color": "#4C566A"
  },
  "hljs-doctag": {
    "color": "#8FBCBB"
  },
  "hljs-meta": {
    "color": "#5E81AC"
  },
  "hljs-meta-keyword": {
    "color": "#5E81AC"
  },
  "hljs-meta-string": {
    "color": "#A3BE8C"
  },
  "hljs-attr": {
    "color": "#8FBCBB"
  },
  "hljs-attribute": {
    "color": "#D8DEE9"
  },
  "hljs-builtin-name": {
    "color": "#81A1C1"
  },
  "hljs-name": {
    "color": "#81A1C1"
  },
  "hljs-section": {
    "color": "#88C0D0"
  },
  "hljs-tag": {
    "color": "#81A1C1"
  },
  "hljs-variable": {
    "color": "#D8DEE9"
  },
  "hljs-template-variable": {
    "color": "#D8DEE9"
  },
  "hljs-template-tag": {
    "color": "#5E81AC"
  },
  "abnf .hljs-attribute": {
    "color": "#88C0D0"
  },
  "abnf .hljs-symbol": {
    "color": "#EBCB8B"
  },
  "apache .hljs-attribute": {
    "color": "#88C0D0"
  },
  "apache .hljs-section": {
    "color": "#81A1C1"
  },
  "arduino .hljs-built_in": {
    "color": "#88C0D0"
  },
  "aspectj .hljs-meta": {
    "color": "#D08770"
  },
  "aspectj > .hljs-title": {
    "color": "#88C0D0"
  },
  "bnf .hljs-attribute": {
    "color": "#8FBCBB"
  },
  "clojure .hljs-name": {
    "color": "#88C0D0"
  },
  "clojure .hljs-symbol": {
    "color": "#EBCB8B"
  },
  "coq .hljs-built_in": {
    "color": "#88C0D0"
  },
  "cpp .hljs-meta-string": {
    "color": "#8FBCBB"
  },
  "css .hljs-built_in": {
    "color": "#88C0D0"
  },
  "css .hljs-keyword": {
    "color": "#D08770"
  },
  "diff .hljs-meta": {
    "color": "#8FBCBB"
  },
  "ebnf .hljs-attribute": {
    "color": "#8FBCBB"
  },
  "glsl .hljs-built_in": {
    "color": "#88C0D0"
  },
  "groovy .hljs-meta:not(:first-child)": {
    "color": "#D08770"
  },
  "haxe .hljs-meta": {
    "color": "#D08770"
  },
  "java .hljs-meta": {
    "color": "#D08770"
  },
  "ldif .hljs-attribute": {
    "color": "#8FBCBB"
  },
  "lisp .hljs-name": {
    "color": "#88C0D0"
  },
  "lua .hljs-built_in": {
    "color": "#88C0D0"
  },
  "moonscript .hljs-built_in": {
    "color": "#88C0D0"
  },
  "nginx .hljs-attribute": {
    "color": "#88C0D0"
  },
  "nginx .hljs-section": {
    "color": "#5E81AC"
  },
  "pf .hljs-built_in": {
    "color": "#88C0D0"
  },
  "processing .hljs-built_in": {
    "color": "#88C0D0"
  },
  "scss .hljs-keyword": {
    "color": "#81A1C1"
  },
  "stylus .hljs-keyword": {
    "color": "#81A1C1"
  },
  "swift .hljs-meta": {
    "color": "#D08770"
  },
  "vim .hljs-built_in": {
    "color": "#88C0D0",
    "fontStyle": "italic"
  },
  "yaml .hljs-meta": {
    "color": "#D08770"
  }
};
exports["default"] = _default;