"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _default = {
  "hljs": {
    "display": "block",
    "overflowX": "auto",
    "padding": "0.5em",
    "background": "linear-gradient(166deg, rgba(80,31,122,1) 0%, rgba(40,32,179,1) 80%)",
    "color": "#e7e4eb"
  },
  "hljs-subtr": {
    "color": "#e7e4eb"
  },
  "hljs-doctag": {
    "color": "#af8dd9"
  },
  "hljs-meta": {
    "color": "#af8dd9"
  },
  "hljs-comment": {
    "color": "#af8dd9"
  },
  "hljs-quote": {
    "color": "#af8dd9",
    "fontStyle": "italic"
  },
  "hljs-selector-tag": {
    "color": "#AEFBFF",
    "fontWeight": "bold"
  },
  "hljs-selector-id": {
    "color": "#AEFBFF",
    "fontWeight": "bold"
  },
  "hljs-template-tag": {
    "color": "#AEFBFF",
    "fontWeight": "bold"
  },
  "hljs-regexp": {
    "color": "#AEFBFF"
  },
  "hljs-attr": {
    "color": "#AEFBFF"
  },
  "hljs-tag": {
    "color": "#AEFBFF"
  },
  "hljs-params": {
    "color": "#F19FFF",
    "fontWeight": "bold"
  },
  "hljs-selector-class": {
    "color": "#F19FFF",
    "fontWeight": "bold"
  },
  "hljs-bullet": {
    "color": "#F19FFF"
  },
  "hljs-keyword": {
    "color": "#17fc95",
    "fontWeight": "bold"
  },
  "hljs-section": {
    "color": "#17fc95",
    "fontWeight": "bold"
  },
  "hljs-meta-keyword": {
    "color": "#17fc95"
  },
  "hljs-symbol": {
    "color": "#17fc95"
  },
  "hljs-type": {
    "color": "#17fc95"
  },
  "hljs-addition": {
    "color": "#E7FF9F"
  },
  "hljs-number": {
    "color": "#C5FE00"
  },
  "hljs-link": {
    "color": "#C5FE00"
  },
  "hljs-string": {
    "color": "#38c0ff"
  },
  "hljs-attribute": {
    "color": "#E7FF9F"
  },
  "hljs-variable": {
    "color": "#E447FF"
  },
  "hljs-template-variable": {
    "color": "#E447FF"
  },
  "hljs-builtin-name": {
    "color": "#FFC800"
  },
  "hljs-built_in": {
    "color": "#FFC800"
  },
  "hljs-formula": {
    "color": "#FFC800"
  },
  "hljs-name": {
    "color": "#FFC800"
  },
  "hljs-title": {
    "color": "#FFC800"
  },
  "hljs-class": {
    "color": "#FFC800"
  },
  "hljs-function": {
    "color": "#FFC800"
  },
  "hljs-selector-pseudo": {
    "color": "#FF9E44"
  },
  "hljs-deletion": {
    "color": "#FF9E44"
  },
  "hljs-literal": {
    "color": "#FF9E44"
  },
  "hljs-emphasis": {
    "fontStyle": "italic"
  },
  "hljs-strong": {
    "fontWeight": "bold"
  }
};
exports["default"] = _default;