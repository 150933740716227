"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _default = {
  "hljs": {
    "display": "block",
    "overflowX": "auto",
    "padding": "0.5em",
    "color": "#333",
    "background": "#fff"
  },
  "hljs-comment": {
    "color": "#777",
    "fontStyle": "italic"
  },
  "hljs-quote": {
    "color": "#777",
    "fontStyle": "italic"
  },
  "hljs-keyword": {
    "color": "#333",
    "fontWeight": "bold"
  },
  "hljs-selector-tag": {
    "color": "#333",
    "fontWeight": "bold"
  },
  "hljs-subst": {
    "color": "#333",
    "fontWeight": "normal"
  },
  "hljs-number": {
    "color": "#777"
  },
  "hljs-literal": {
    "color": "#777"
  },
  "hljs-string": {
    "color": "#333",
    "background": "url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAYAAACp8Z5+AAAAJ0lEQVQIW2O8e/fufwYGBgZBQUEQxcCIIfDu3Tuwivfv30NUoAsAALHpFMMLqZlPAAAAAElFTkSuQmCC) repeat"
  },
  "hljs-doctag": {
    "color": "#333",
    "background": "url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAYAAACp8Z5+AAAAJ0lEQVQIW2O8e/fufwYGBgZBQUEQxcCIIfDu3Tuwivfv30NUoAsAALHpFMMLqZlPAAAAAElFTkSuQmCC) repeat"
  },
  "hljs-formula": {
    "color": "#333",
    "background": "url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAYAAACp8Z5+AAAAJ0lEQVQIW2O8e/fufwYGBgZBQUEQxcCIIfDu3Tuwivfv30NUoAsAALHpFMMLqZlPAAAAAElFTkSuQmCC) repeat"
  },
  "hljs-title": {
    "color": "#000",
    "fontWeight": "bold"
  },
  "hljs-section": {
    "color": "#000",
    "fontWeight": "bold"
  },
  "hljs-selector-id": {
    "color": "#000",
    "fontWeight": "bold"
  },
  "hljs-class .hljs-title": {
    "color": "#333",
    "fontWeight": "bold"
  },
  "hljs-type": {
    "color": "#333",
    "fontWeight": "bold"
  },
  "hljs-name": {
    "color": "#333",
    "fontWeight": "bold"
  },
  "hljs-tag": {
    "color": "#333"
  },
  "hljs-regexp": {
    "color": "#333",
    "background": "url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAICAYAAADA+m62AAAAPUlEQVQYV2NkQAN37979r6yszIgujiIAU4RNMVwhuiQ6H6wQl3XI4oy4FMHcCJPHcDS6J2A2EqUQpJhohQDexSef15DBCwAAAABJRU5ErkJggg==) repeat"
  },
  "hljs-symbol": {
    "color": "#000",
    "background": "url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAAKElEQVQIW2NkQAO7d+/+z4gsBhJwdXVlhAvCBECKwIIwAbhKZBUwBQA6hBpm5efZsgAAAABJRU5ErkJggg==) repeat"
  },
  "hljs-bullet": {
    "color": "#000",
    "background": "url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAAKElEQVQIW2NkQAO7d+/+z4gsBhJwdXVlhAvCBECKwIIwAbhKZBUwBQA6hBpm5efZsgAAAABJRU5ErkJggg==) repeat"
  },
  "hljs-link": {
    "color": "#000",
    "background": "url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAAKElEQVQIW2NkQAO7d+/+z4gsBhJwdXVlhAvCBECKwIIwAbhKZBUwBQA6hBpm5efZsgAAAABJRU5ErkJggg==) repeat"
  },
  "hljs-built_in": {
    "color": "#000",
    "textDecoration": "underline"
  },
  "hljs-builtin-name": {
    "color": "#000",
    "textDecoration": "underline"
  },
  "hljs-meta": {
    "color": "#999",
    "fontWeight": "bold"
  },
  "hljs-deletion": {
    "color": "#fff",
    "background": "url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAADCAYAAABS3WWCAAAAE0lEQVQIW2MMDQ39zzhz5kwIAQAyxweWgUHd1AAAAABJRU5ErkJggg==) repeat"
  },
  "hljs-addition": {
    "color": "#000",
    "background": "url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAJCAYAAADgkQYQAAAALUlEQVQYV2N89+7dfwYk8P79ewZBQUFkIQZGOiu6e/cuiptQHAPl0NtNxAQBAM97Oejj3Dg7AAAAAElFTkSuQmCC) repeat"
  },
  "hljs-emphasis": {
    "fontStyle": "italic"
  },
  "hljs-strong": {
    "fontWeight": "bold"
  }
};
exports["default"] = _default;