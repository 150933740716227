"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _default = {
  "hljs-comment": {
    "color": "#8d8d8d"
  },
  "hljs-quote": {
    "color": "#b3c7d8"
  },
  "hljs-variable": {
    "color": "#cc6666"
  },
  "hljs-template-variable": {
    "color": "#cc6666"
  },
  "hljs-tag": {
    "color": "#cc6666"
  },
  "hljs-name": {
    "color": "#cc6666"
  },
  "hljs-selector-id": {
    "color": "#cc6666"
  },
  "hljs-selector-class": {
    "color": "#cc6666"
  },
  "hljs-regexp": {
    "color": "#cc6666"
  },
  "hljs-deletion": {
    "color": "#cc6666"
  },
  "hljs-number": {
    "color": "#de935f"
  },
  "hljs-built_in": {
    "color": "#de935f"
  },
  "hljs-builtin-name": {
    "color": "#de935f"
  },
  "hljs-literal": {
    "color": "#de935f"
  },
  "hljs-type": {
    "color": "#de935f"
  },
  "hljs-subst\n.hljs-link": {
    "color": "#de935f"
  },
  "hljs-attribute": {
    "color": "#f0c674"
  },
  "hljs-string": {
    "color": "#b5bd68"
  },
  "hljs-bullet": {
    "color": "#b5bd68"
  },
  "hljs-params": {
    "color": "#b5bd68"
  },
  "hljs-addition": {
    "color": "#b5bd68"
  },
  "hljs-title": {
    "color": "#81a2be"
  },
  "hljs-meta": {
    "color": "#81a2be"
  },
  "hljs-section": {
    "color": "#81a2be"
  },
  "hljs-selector-tag": {
    "color": "#be94bb"
  },
  "hljs-keyword": {
    "color": "#be94bb"
  },
  "hljs-function": {
    "color": "#be94bb"
  },
  "hljs-class": {
    "color": "#be94bb"
  },
  "hljs-symbol": {
    "color": "#dbc4d9"
  },
  "hljs": {
    "display": "block",
    "overflowX": "auto",
    "background": "#303030",
    "color": "#c5c8c6",
    "padding": "0.5em"
  },
  "hljs-emphasis": {
    "fontStyle": "italic"
  },
  "hljs-strong": {
    "fontWeight": "bold"
  }
};
exports["default"] = _default;